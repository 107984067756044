.so_container {
    display: flex;
    width: 100%;
    background: #f8f8f8;
    flex-direction: column;
    align-items: center;
    .so_top_bg {
        width: 100%;
        // height: 600px;
        background-position: center center;
        // background-size: 100% 600px;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("../../../../assets/solution/top_bg.png");
        height: 0;
        position: relative;
        padding-bottom: 37.5%;
        .bannerBox{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
        .title {
            font-size: 38px;
            font-family:
                PingFangSC,
                PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 55px;
            letter-spacing: 3px;
            margin-top: 60px;
        }
        .main_title {
            font-size: 54px;
            // font-weight: 800;
            font-weight: bold;
            color: #ffffff;
            line-height: 55px;
            letter-spacing: 4px;
            // -webkit-text-stroke: 1px #ffffff;
            // text-stroke: 1px #ffffff;
            margin-top: 52px;
        }
        .sub_title {
            font-size: 17px;
            font-family:
                PingFangSC,
                PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 20px;
            letter-spacing: 1px;
            margin-top: 24px;
        }
    }
}
