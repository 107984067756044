.TopBanner {
  position: relative;
  // margin-top: -80px;
  width: 100%;
  height: 0;
  padding-bottom: 37.5%;

  .contentBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .imageBox {
      width: 100%;
      height: 100%;
      position: relative;

      .bgImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .videoBox {
      width: 100%;
      height: 100%;

      .bgVideo {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
}

.bannerContent {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  flex-direction: column;
  display: flex;
  justify-content: center;

  .titleEnglish {
    margin-top: 100px;
    height: 56px;
    font-size: 40px;
    color: #ffffff;
  }

  .titleEnglish_mobile {
    font-size: 24px;
    font-weight: 600;
    color: #ffffff;
    line-height: 26px;
    margin-top: 36px;
  }

  .line {
    width: 50px;
    height: 2px;
    background-color: #ffffff;
    margin: 8px 0 12px;
  }

  .line_mobile {
    width: 35px;
    height: 1px;
    background-color: #ffffff;
    margin: 10px 0 12px;
  }

  .title {
    font-size: 28px;
    line-height: 38px;
    color: #ffffff;
  }

  .title_mobile {
    font-size: 18px;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
  }
}