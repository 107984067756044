.nc_container {
    position: relative;
    display: flex;
    flex-direction: column;

    .nc_title {
        z-index: 1;
        font-size: 28px;
        font-family:
            PingFangSC,
            PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 36px;
        margin-block: 40px;
    }
    .nc_big_item {
        position: relative;
        display: flex;
        width: 952px;
        height: 618px;
        border-radius: 3px;
        overflow: hidden;
        flex-direction: column;
        cursor: pointer;
        .nc_big_item_img_bg {
            width: 100%;
            height: 450px;
            overflow: hidden;
            .nc_big_item_img {
                width: 100%;
                background-position: center;
                background-size: cover;
                transition: transform 0.5s ease;
            }
        }
        .nc_big_item_cover {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            height: 168px;
            background-color: #1c5db9;
            .nc_big_item_cover_title {
                margin-left: 40px;
                margin-right: 40px;
                font-size: 24px;
                font-family:
                    PingFangSC,
                    PingFang SC;
                font-weight: 440;
                color: #ffffff;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .nc_big_item_cover_sub_title {
                font-size: 15px;
                font-family:
                    PingFangSC,
                    PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 21px;
                margin-top: 15px;
                margin-left: 40px;
            }
        }
    }
    .nc_big_item:hover .nc_big_item_img_bg .nc_big_item_img {
        transform: scale(1.05);
    }

    .nc_small_item {
        position: relative;
        display: flex;
        // width: 464px;
        height: 618px;
        border-radius: 3px;
        overflow: hidden;
        flex-direction: column;

        cursor: pointer;
        .nc_small_item_img_bg {
            width: 100%;
            height: 308px;
            overflow: hidden;
            .nc_small_item_img {
                width: 100%;
                background-position: center;
                background-size: cover;
                transition: transform 0.5s ease;
            }
        }

        .nc_small_item_bottom {
            display: flex;
            width: 100%;
            height: 310px;
            background-color: white;
            flex-direction: column;

            .title {
                font-size: 24px;
                font-family:
                    PingFangSC,
                    PingFang SC;
                font-weight: 440;
                color: #333333;
                line-height: 35px;
                height: 105px;
                overflow: hidden;
                margin: 30px;
                margin-top: 50px;
                margin-bottom: 65px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                height: 105px;
              }
              .time_bg {
                border-top: 0.5px solid hsla(0, 0%, 60%, 0.58);
                display: flex;
                flex-direction: row;
                margin-left: 30px;
                margin-right: 30px;
                padding-top: 20px;
                justify-content: space-between;
                .time {
                    font-size: 15px;
                    font-family:
                        PingFangSC,
                        PingFang SC;
                    font-weight: 400;
                    color: #222222;
                    line-height: 21px;
                }
                .time_more_img {
                    margin-left: 5px;
                    width: 14px;
                    height: 14px;
                    background-image: url("../../../../assets/home/more.png");
                    background-size: cover;
                    background-position: 0 0;
                    background-repeat: no-repeat;
                    transition: transform 0.3s ease;
                }
            }

            .more_bg {
                position: absolute;
                right: 30px;
                bottom: 37px;
                align-items: center;
                justify-content: flex-end;
                display: flex;
                flex-direction: row;
                cursor: pointer;
                .more_title {
                    font-size: 15px;
                    font-family:
                        PingFangSC,
                        PingFang SC;
                    font-weight: 400;
                    color: #919191;
                    line-height: 21px;
                    transition: transform 0.3s ease;
                }
                .more_img {
                    margin-left: 5px;
                    width: 16px;
                    height: 16px;
                    background-image: url("../../../../assets/home/more.png");
                    background-size: cover;
                    background-position: 0 0;
                    background-repeat: no-repeat;
                    transition: transform 0.3s ease;
                }
                &:hover {
                    .more_title {
                        transform: translateX(5px);
                    }
                    .more_img {
                        transform: translateX(10px);
                    }
                }
            }
        }
    }
    .nc_small_item:hover .nc_small_item_img_bg .nc_small_item_img {
        transform: scale(1.05);
    }
    .nc_small_item:hover .nc_small_item_bottom {
        background-color: #1c5db9;
    }
    .nc_small_item:hover .nc_small_item_bottom .more_bg .more_img {
        background-image: url("../../../../assets/home/more_white.png");
    }
    .nc_small_item:hover .nc_small_item_bottom .more_bg .more_title {
        color: #ffffff;
    }
    .nc_small_item:hover .nc_small_item_bottom .title,
    .nc_small_item:hover .nc_small_item_bottom .time {
        color: #ffffff;
    }
    .nc_small_item:hover .nc_small_item_bottom .time_more_img {
        background-image: url("../../../../assets/home/more_white.png");
    }
    .nc_small_item:hover .nc_small_item_bottom .time_bg {
        border-top-color: #fff;
    }
}
