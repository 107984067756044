.CompanyProfileIndexPage {
  padding-bottom: 115px;
  background-size: 761px 573px;
  // width: 1200px;
  height: 573px;
  background-image: url("../../../../../assets/images/com_bg@2x.png");
  background-position: right bottom;
  background-repeat: no-repeat;
  margin-top: -16px;

  .title {
    font-size: 22px;
    line-height: 20px;
    color: #333333;
    margin-top: 20px;
    font-weight: bold;
  }

  .text {
    // width: 713px;
    // margin-top: 36px;
    margin-left: 80px;

    p {
      font-family:
        PingFangSC,
        PingFang SC;
      font-weight: 400;

      text-indent: 30px;
      font-size: 15px;
      line-height: 30px;
      color: #333333;
      &.small {
        font-size: 14px;
        color: #919191;
        text-indent: 0;
      }
    }
  }
}
